import View from "./components/View";


function Landing() {
  return (
    <View
      title="經濟部水利署災防通訊錄"
      >
      <article>
        <p>此系統為經濟部水利署「災害防救聯絡電話號碼簿」，本電話簿僅供防汛期防災應變相關人員通報災情之用，請各單位參照去年度電話簿進行更新，亦可自行新增，爾後皆改為線上系統辦理，各單位若有人員或電話異動可即時更新。</p>
        <p className="text-danger">注意：通訊錄涉及個資，限應用於防救災。請勿將機敏資料外流。</p>
        <p>帳號與密碼需要調整，或有任何疑問，可洽：</p>
        <ul>
          <li>
            本署水利防災組 朱先生
            <ul>
              <li>聯絡電話：02-3707-3039</li>
              <li>E-mail：pychu@wra.gov.tw</li>
            </ul>
          </li>
          <li>
            協力團隊 詹先生
            <ul>
              <li>聯絡電話：02-2733-3141 #7508</li>
              <li>E-mail：yilin310310@gmail.com</li>
            </ul>
          </li>
        </ul>
      </article>
    </View>
  );
}


export default Landing;
