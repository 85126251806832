function View({title, children}) {
  return (
    <>
      <h1>{title}</h1>
      <hr/>
      {children}
    </>
  );
}


function NotFound() {
  return <View title="Page Not Found" />;
}


export default Object.assign(View, {
  NotFound,
});
