import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";


function MessageBox({ children, variant = 'warning', ...rest }) {
  if (!children) return <></>;

  return (
    <Alert
      className="mt-3"
      variant={variant}
      {...rest}
      >
      { children }
    </Alert>
  );
}


function ErrorBox({ error, ...rest }) {
  if (!error) return <></>;

  let following;
  if (error.cause === 401) {
    following = <Link to="/login">重新登入</Link>;
  }

  return (
    <MessageBox {...rest}>
      <span>{error.message}</span>
      {'  '}
      {following}
    </MessageBox>
  );
}


export {
  MessageBox,
  ErrorBox,
}
