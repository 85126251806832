import { useState, useRef, useReducer } from "react";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";

import Stack from "react-bootstrap/Stack";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from 'react-bootstrap/Modal';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useDataApi, Pending } from "../Data";
import { MessageBox, ErrorBox } from "../components/Utils";


const LAYOUT_LABEL = {
  sm: 1,
  md: 2,
}
const LAYOUT_INPUT = {
  sm: 11,
  md: 10,
}


const OPTIONS = [
  '辦公室',
  '住家',
  '手機',
  '傳真',
];


function emptyMember() {
  return {
    name: '',
    position: '',
    contacts: [],
    for_emergency: false,
    for_unit: false,
  }
}


function updateReducer(info, updating) {
  let copied = JSON.parse(JSON.stringify(info));

  for (let [f, v] of Object.entries(updating)) {
    let attrs = f.split('.');

    let cursor = copied;
    for (let i = 0; i < attrs.length - 1; ++i) cursor = cursor[attrs[i]];
    cursor[attrs[attrs.length - 1]] = JSON.parse(JSON.stringify(v));
  }
  return copied;
}


function Member({ unitId, ...info }) {
  let [ dispatch, resetDispatch, { data, error, isLoading } ] = useDataApi();

  const navigate = useNavigate();
  const location = useLocation();

  const fromCreate = !!(location.state?.fromCreate);
  const onCreate = Object.keys(info).length === 0;

  const submitTerm = (onCreate) ? '新增' : '更新';
  const backTo = `/book/units/${unitId}`;

  let [ isEditing, setEdit ] = useState(!fromCreate);
  let [ isSuccess, setSuccess ] = useState(fromCreate);

  let [ curInfo, updateInfo ] = useReducer(updateReducer, (onCreate) ? emptyMember() : info);
  let [ showModal, setModal ] = useState(false);

  function buildContactUpdate(idx) {
    return updating => {
      let adapted = Object.assign(
        {}, ...Object.keys(updating).map(e => ({[`contacts.${idx}.${e}`]: updating[e]})));
      return updateInfo(adapted);
    }
  }

  function buildContactRemove(idx) {
    return () => updateInfo({contacts: curInfo.contacts.filter((_, ci) => ci !== idx)});
  }

  function handleContactAdd(e) {
    updateInfo({
      contacts: [
        ...((curInfo.contacts) ? curInfo.contacts : []),
        {
          type: {display: '辦公室'},
          value: '',
          note: '',
        },
      ],
    });
  }

  function handleDelete() {
    setEdit(false);

    async function deleteMember() {
      await dispatch(`/units/${unitId}/members/${curInfo._id}`, 'DELETE');
    }

    deleteMember();
    setModal(false);
  }

  function handleReset(e) {
    e.preventDefault();
    navigate(backTo);
  }

  function handleSubmit(e) {
    e.preventDefault();

    setEdit(false);

    let formData = new FormData(e.target);

    let types = formData.getAll('type').map(e => ({type: e}));
    let values = formData.getAll('value').map(e => ({value: e?.trim()}));
    let notes = formData.getAll('note').map(e => ({note: e?.trim()}));

    let contacts = types.map((e, i) => Object.assign(e, {...values[i], ...notes[i]}));
    if (!contacts.length) {
      throw Error('必須設置至少一筆電話');
    }

    let post = {
      name: formData.get('name')?.trim(),
      position: formData.get('position')?.trim(),
      for_emergency: formData.get('for_emergency') === '1',
      for_unit: formData.get('for_unit') === '1',
      contacts: contacts,
    }

    // if ((post.for_unit) && (contacts.every(e => e.type === '傳真'))) {
    //   throw Error('設為單位/辦公室傳真的聯絡人，僅可設置傳真資訊');
    // }

    async function createOrUpdate() {
      if (onCreate) {
        await dispatch(`/units/${unitId}/members`, 'POST', post);
      } else {
        await dispatch(`/units/${unitId}/members/${curInfo._id}`, 'PUT', post);
      }
    }

    createOrUpdate();
  }

  if (isLoading) return <Pending />;

  if (!!error && !isEditing) setEdit(true);
  if (!!data && !isSuccess) setSuccess(true);

  if (onCreate && isSuccess) {
    const url = `/book/units/${unitId}/members/${data._id}`;
    return <Navigate to={url} state={{ fromCreate: true }} />;
  }

  let actions = (
    <Stack as={Col} direction="horizontal" gap={2}>
      <Button key="reset" type="reset" variant="secondary">取消</Button>
      <Button key="submit" type="submit">{submitTerm || '儲存'}</Button>
      {
        (!onCreate) ?
        <>
          <div className="vr ms-auto" />
          <Button key="remove" onClick={() => setModal(true)} variant="danger">刪除</Button>
        </>
        :
        <></>
      }
    </Stack>
  );

  let messageSuccess = (
    <MessageBox variant="success">
      <span>{`完成${submitTerm}`}</span>
      {'  '}
      {
        (onCreate) ?
        <Link to={`/book/units/${unitId}/members`}>新增更多</Link> :
        <Link to={backTo}>回列表</Link>
      }
    </MessageBox>
  );

  return (
    <>
    <Container
      fluid
      as={Form}
      onSubmit={handleSubmit}
      onReset={handleReset}
      >
      <TextEdit
        label="姓名"
        control="name"
        value={curInfo.name}
        onChange={e => updateInfo({name: e.target.value})}
        isEditing={isEditing}
        required
        />
      <TextEdit
        label="職稱"
        control="position"
        value={curInfo.position}
        onChange={e => updateInfo({position: e.target.value})}
        isEditing={isEditing}
        />
      <Row className="mt-3">
        <Col>
          <BinarySwitch
            id="for_emergency"
            name="for_emergency"
            label="設為應變聯絡人"
            checked={curInfo.for_emergency}
            onChange={e => updateInfo({for_emergency: e.target.checked})}
            disabled={!isEditing}
            />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <BinarySwitch
            id="for_unit"
            name="for_unit"
            label="設為單位/辦公室傳真"
            checked={curInfo.for_unit}
            onChange={e => updateInfo({for_unit: e.target.checked})}
            disabled={!isEditing}
            />
        </Col>
      </Row>
      <Form.Group as={Row} className="mt-3">
        <Form.Label column {...LAYOUT_LABEL}>
          電話
        </Form.Label>
        <Col {...LAYOUT_INPUT}>
          {curInfo.contacts?.map(
            (e, i) => <ContactEdit
              key={i}
              {...e}
              isEditing={isEditing}
              updateMember={buildContactUpdate(i)}
              removeMember={buildContactRemove(i)}
              />
          )}
          {(isEditing) ? <Button variant="link" onClick={handleContactAdd}>新增</Button> : <></>}
        </Col>
        {(!onCreate) ?
          (<Form.Text as={Row} className="mt-3">
            最近更新：{(new Date(info.update_at).toLocaleString())}
          </Form.Text>) : <></>
        }
      </Form.Group>
      <Row className="mt-3">
        {(isEditing) ? actions : <></>}
      </Row>
      <Row>
        {<ErrorBox error={error} />}
        {(isSuccess) ? messageSuccess : <></>}
      </Row>
    </Container>
    <Modal
      show={showModal}
      >
      <Modal.Body>
        <p>確認要刪除{info.name}嗎？</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModal(false)}>取消</Button>
        <Button variant="danger" onClick={handleDelete}>確認刪除</Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}


function TextEdit({ label, control, isEditing = false, ...rest }) {
  return (
    <Form.Group as={Row} className="mt-3" controlId={control}>
      <Form.Label column {...LAYOUT_LABEL}>
        {label}
      </Form.Label>
      <Col {...LAYOUT_INPUT}>
        <Form.Control
          type="text"
          name={control}
          readOnly={!isEditing}
          plaintext={!isEditing}
          {...rest}
          />
      </Col>
    </Form.Group>
  );
}


function BinarySwitch({ name = undefined, onChange = undefined, ...rest }) {
  let inputRef = useRef(null);

  function handleChange(e) {
    if (!onChange) return;

    onChange(e);
    inputRef.current.value = (e.target.checked) ? 1 : 0;
  }

  return (<>
    <Form.Check
      type="switch"
      onChange={handleChange}
      {...rest}
      />
    <input
      type="hidden"
      name={name}
      value={(rest.checked) ? 1 : 0}
      ref={inputRef}
      />
  </>);
}


function ContactEdit({ type, value, note, updateMember, removeMember, isEditing = false }) {
  return (
    <Form.Group as={Stack} direction="horizontal" className="mb-3">
      <Row className="me-auto">
        <Form.Group as={Col} md={4}>
          <Form.Select
            name="type"
            value={type?.display}
            readOnly={!isEditing}
            disabled={!isEditing}
            onChange={e => updateMember({type: {display: e.target.value}})}
            >
            <option disabled>請選擇</option>
            {OPTIONS.map(e => <option value={e} key={e}>{e}</option>)}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md={5}>
          <Form.Control
            name="value"
            placeholder="號碼（含分機）"
            value={value}
            readOnly={!isEditing}
            plaintext={!isEditing}
            onChange={e => updateMember({value: e.target.value})}
            required
            />
          <Form.Control.Feedback type="invalid" tooltip>
            請以正確格式輸入
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Control
            name="note"
            placeholder="備註說明"
            value={note}
            readOnly={!isEditing}
            plaintext={!isEditing}
            onChange={e => updateMember({note: e.target.value})}
            />
        </Form.Group>
      </Row>
      {(isEditing) ? <Button variant="link" onClick={removeMember}>刪除</Button> : <></>}
    </Form.Group>
  );
}


function asLink({ unitId, _id, name }) {
  return { to: `/book/units/${unitId}/members/${_id}`, name: name };
}


export default Object.assign(Member, { asLink });
