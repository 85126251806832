import { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";

import { Navigate } from "react-router-dom";

import { useToken } from "./Utils";
import { throwResponseError } from "./Data";
import { Pending } from "./Data";

import { MessageBox } from "./components/Utils";



async function login(formData) {
  let resp = await fetch(
    '/api/oauth/token',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(formData),
    });

  if (resp.ok) {
    return await resp.json();
  }
  await throwResponseError(resp);
}


function Login() {
  let [ error, setError ] = useState(null);
  let [ isLoading, setLoading ] = useState(false);
  let [ token, setToken ] = useToken();

  function handleSubmit(e) {
    e.preventDefault();

    let formData = new FormData(e.target);
    if ((!formData.get('username')) || (!formData.get('password'))) {
      setError({type: 'warning', message: '未提供帳號或密碼'});
      return;
    }

    setLoading(true);

    async function dispatch() {
      try {
        let r = await login(formData);
        setToken(r);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    }

    dispatch();
  }

  if (!!token) return <Navigate to="/" />;
  if (isLoading) return <Pending />;

  return (
    <Stack
      gap={3}
      style={{maxWidth: '20em', margin: '1em auto'}}
      as={Form}
      onSubmit={handleSubmit}
      >
      <Form.Group className="mb-3" controlId="formUsername">
        <Form.Label>帳號</Form.Label>
        <Form.Control
          placeholder="輸入帳號"
          name="username"
          autoComplete="username"
          required
          />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formPassword">
        <Form.Label>密碼</Form.Label>
        <Form.Control
          type="password"
          placeholder="輸入密碼"
          name="password"
          autoComplete="current-password"
          required
          />
      </Form.Group>
      <Button variant="primary" type="submit">
        登入
      </Button>
      <MessageBox>{error?.message}</MessageBox>
    </Stack>
  );
}


function Logout() {
  let [ token, setToken ] = useToken();
  if (!!token) setToken(null);
  return <Navigate to='/login' />;
}


export default {
  Login,
  Logout,
};
