import { useState, createContext } from "react";


const storage = window.localStorage;

const UserContext = createContext(null);


/** User's window access token */

function getToken() {
  const tokenString = storage.getItem('token');
  try {
    const payload = JSON.parse(tokenString);
    return payload?.access_token;
  } catch (e) {
    return undefined;
  }
}


function useToken() {
  const [ token, setToken ] = useState(getToken());

  const saveToken = r => {
    if (!!r) storage.setItem('token', JSON.stringify(r));
    else storage.removeItem('token');
    setToken(r?.access_token);
  };

  return [ token, saveToken ];
}


function IconSvg({ identifier, children }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16" height="16" fill="currentColor"
      className={'bi bi-' + identifier}
      viewBox="0 0 16 16"
      >
      {children}
    </svg>
  );
}


export {
  useToken,
  IconSvg,
  getToken,
  UserContext,
};
