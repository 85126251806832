import { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";

import { getToken } from "./Utils";


async function throwResponseError(response) {
  let message;

  if (response.status < 500) {
    message = (await response.json())?.detail;
  } else {
    throw new Error(`錯誤：${ await response.text() }`, { cause: response.status });
  }

  switch (response.status) {
    case 400:
      throw new Error(`輸入資料錯誤：${message}`, { cause: 400 });

    case 401:
      throw new Error(`身分驗證失敗：${message}`, { cause: 401 });

    case 403:
      throw new Error(`權限不足：${message}`, { cause: 403 });

    case 404:
      throw new Error(`找不到資料：${message}`, { cause: 404 });

    default:
      throw new Error(`錯誤：${message}`, { cause: response.status });
  }
}


async function fetchApi(token, path, method = 'GET', data = null) {
  let url = `/api${path}`;
  let body;

  const isGet = method.toUpperCase() === 'GET';

  if (!!data) {
    if (isGet) {
      let params = new URLSearchParams(data);
      url = `${url}?${params.toString()}`;
    } else {
      body = JSON.stringify(data);
    }
  }

  let resp = await fetch(
    url,
    {
      method: method,
      headers: {
        'Authorization': `Bearer ${token}`,
        ...((!isGet) ? {'Content-Type': 'application/json'} : {}),
      },
      ...((!!body) ? {body: body} : {})
    }
  );

  if (resp.ok) {
    if (resp.status === 204) return true;
    else return await resp.json();
  }

  await throwResponseError(resp);
}


function fetchData(token, path, method = 'GET', data = null) {
  return fetchApi(token, `/data${path}`, method, data);
}


function fetchDataWithToken(path, method = 'GET', data = null) {
  return fetchData(getToken(), path, method, data)
}


function useFetch(fetchFnc, initData = null) {
  let [ args, _setDispatch ] = useState([]);
  const setDispatch = (...args) => _setDispatch(args);

  let [ data, setData ] = useState(initData);
  let [ error, setError ] = useState(null);
  let [ isLoading, setLoading ] = useState(false);

  function resetDispatch() {
    setData(initData);
    setError(null);
    setLoading(false);
  }

  useEffect(() => {
    if (args.length === 0) return;

    const token = getToken();
    let canceled = false;

    async function dispatch() {
      setLoading(true);
      setError(null);
      setData(initData);

      try {
        let r = await fetchFnc(token, ...args)
        if (!canceled) setData(r);
      } catch (err) {
        if (!canceled) setError(err);
      }

      if (!canceled) setLoading(false);
    }

    dispatch();
    return () => {canceled = true};
  }, [ args ]);

  return [ setDispatch, resetDispatch, { data, error, isLoading } ];
}


function useApi(initData) {
  return useFetch(fetchApi, initData);
}


function useDataApi(initData) {
  return useFetch(fetchData, initData);
}


function Pending() {
  return (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}


export {
  fetchApi,
  fetchData,
  fetchDataWithToken,
  throwResponseError,
  useApi,
  useDataApi,
  Pending,
}
